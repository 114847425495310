/* ==========================================================================
   Site footer
   ========================================================================== */

.footer-wrapper {
  @include clearfix;
  margin: 2em auto;
  text-align: center;
  font-family: $alt-font;
  @include font-size(14);
  font-style: italic;
  color: lighten($text-color,20);

  a {
    color: lighten($text-color,20);
  }
}

.legaltext {
  @include font-size(8);
}


/*
   Social media icons
   ========================================================================== */

.social-icons {
  margin: 1em 0 2em;

  a {
    padding: 4px 8px;

    &:hover {
      color: $black;
    }
  }
}
